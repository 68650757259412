<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row>
        <v-col cols="12" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="6" xl="6" xxl="6" class="mx-auto">
              <v-card flat>
                <v-img
                  src="@/assets/vizuro_logo.png"
                  :width="200"
                ></v-img>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Welcome to Karma 360!
                    <!-- Display version information -->
                    <small> v{{ appVersion }} </small>
                  </p>
                  <p class="mb-2">
                    Please sign-in to your account and start the adventure
                  </p>
                </v-card-text>
<!-- 
                <v-card-text>
                  <v-alert text color="primary">
                    <small>
                      Client Email: <strong>exhibition@vizuro.com</strong> /
                      Pass:
                      <strong>exhibition</strong>
                    </small>
                  </v-alert>
                </v-card-text> -->

                <!-- login form -->
                <v-card-text>
                  <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      :error-messages="errorMessages"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      placeholder="Password"
                      :append-icon="
                        isPasswordVisible
                          ? 'mdi-eye-off-outline'
                          : 'mdi-eye-outline'
                      "
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div
                      class="d-flex align-center justify-space-between flex-wrap"
                    >
                      <!-- <v-checkbox hide-details label="Remember Me" class="mt-0">
                      </v-checkbox> -->

                      <!-- forget link -->
                      <!-- <router-link
                        :to="{ name: 'auth-forgot-password' }"
                        class="ms-3"
                      >
                        Forgot Password?
                      </router-link> -->
                    </div>

                    <v-btn block color="primary" type="submit" class="mt-6">
                      Login
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <!-- <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <p class="mb-0 me-2">New on our platform?</p>
                  <router-link :to="{ name: 'auth-register' }">
                    Create an account
                  </router-link>
                </v-card-text> -->
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import data from "@/models/data";

export default {
  data: () => ({
    loginForm: null,
    isPasswordVisible: false,
    email: null,
    password: null,
    errorMessages: null,
    appName: "Karma 360",
    appVersion: "",
    API_KEY: process.env.VUE_APP_API_KEY,
  }),
  methods: {
    async handleFormSubmit() {
      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */
      this.errorMessages = null;
      try {
        let res = await data.userLogin({
          email: this.email,
          password: this.password,
        });

        localStorage.setItem("accessToken", res.access_token);
        localStorage.setItem("refreshToken", res.refresh_token);
        localStorage.setItem("username", this.email); // for causal_chatbot query

        let response = await data.userProfile(res.access_token);
        localStorage.setItem("userData", JSON.stringify(response));

        // if login successfully ,than run the code below
        window.pendo.initialize({
          visitor: {
            id: this.email, // Required if user is logged in, default creates anonymous ID
            email: this.email, // Recommended if using Pendo Feedback, or NPS Email
          },

          account: {
            id: "ACCOUNT-UNIQUE-ID", // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
          },
        });

        this.$router.push("/");
      } catch (error) {
        this.$dialog.message.error("Invalid account or password.", {
          position: "top",
        });
      }
    },
    init(apiKey) {
      (function(p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
        for (w = 0, x = v.length; w < x; ++w)
          (function(m) {
            o[m] =
              o[m] ||
              function() {
                o._q[m === v[0] ? "unshift" : "push"](
                  [m].concat([].slice.call(arguments, 0))
                );
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, "script", "pendo");
    },
  },
  created() {
    // Load the version from package.json
    this.appVersion = require("../../package.json").version;
    this.init(this.API_KEY);
  },
};
</script>

<style lang="scss" scoped></style>
